import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import store from "store";
function authGuard(WrappedComponent) {
  return class HOC extends Component {
    render() {
      const { history } = this.props;
      const isAuthenticated = JSON.parse(localStorage.getItem("user"));
      if (window.location.pathname == "/") {
        if (
          window.location.hostname != "onesaz.com" &&
          window.location.hostname != "www.onesaz.com" &&
          !window.location.hostname.includes("localhost")
        ) {
          if (isAuthenticated) history.push("/home");
          else {
            history.push("/sign-in");
          }
          return null;
        }
        if (isAuthenticated && !isAuthenticated._id.includes("mocktest_")) {
          history.push("/home");
        }
      }

      if (!isAuthenticated && window.location.pathname != "/") {
        history.push("/sign-in");
        return null;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default (WrappedComponent) => withRouter(authGuard(WrappedComponent));
