import { ThemeProvider, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import UpdateIcon from "@material-ui/icons/Update";
import React from "react";
import theme from "../assets/theme";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} />;
});
const AlertComponent = ({
  children,
  open,
  closeAlert,
  alertType,
  btnText = "Close",
  ifThemeRequired = true,
}) => {
  let title, color;
  if (alertType === "error") {
    title = "Error!";
    color = "error";
  }
  if (alertType === "warning") {
    title = "Warning!";
    color = "textPrimary";
  }
  if (alertType === "success") {
    title = "Success!";
    color = "primary";
  }
  if (alertType === "update") {
    title = "Update!";
    color = "textPrimary";
  }
  return (
    <ThemeProvider theme={ifThemeRequired ? theme : null}>
      <Dialog
        open={open}
        fullWidth
        onClose={closeAlert}
        aria-labelledby="confirm-dialog"
        TransitionComponent={Transition}
      >
        <DialogTitle id="confirm-dialog" align="center">
          <Typography variant="subtitle1" color={color}>
            {(() => {
              if (title === "Error!") {
                return (
                  <>
                    <ErrorOutlineOutlinedIcon />
                    <br />
                    {title}
                  </>
                );
              }
              if (title === "Warning!") {
                return (
                  <>
                    <WarningOutlinedIcon />
                    <br />
                    {title}
                  </>
                );
              }
              if (title === "Update!") {
                return (
                  <>
                    <UpdateIcon />
                    <br />
                    {title}
                  </>
                );
              }
              if (title === "Success!") {
                return (
                  <>
                    <CheckCircleTwoToneIcon />
                    <br />
                    {title}
                  </>
                );
              } else return <WarningOutlinedIcon fontSize="large" />;
            })()}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" align="center">
            {children}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAlert}>
            <Typography color="textSecondary">{btnText}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};
export default AlertComponent;
