import axios from "./examServerAxios";
import store from "store";
import { adminAxios } from "./baseAxios";

export async function userLogin(body) {
  console.log("====================================");
  console.log(body);
  console.log("====================================");
  try {
    let data = await axios.post("/users/login", body);
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    return data;
  } catch (error) {
    console.log("hello");
    return error;
  }
}

export async function parentLoginApi(body) {
  console.log("====================================");
  console.log(body);
  console.log("====================================");
  try {
    let data = await axios.post("/users/parentLogin", body);
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    return data;
  } catch (error) {
    console.log("hello");
    return error;
  }
}

export async function sendOTP(body) {
  console.log("====================================");
  console.log(body);
  console.log("====================================");
  try {
    let data = await axios.post("/users/createotp", body);
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    return data;
  } catch (error) {
    return error;
  }
}

export async function userRegister(body) {
  console.log("====================================");
  console.log(body);
  console.log("====================================");
  try {
    let data = await axios.post("/users/registerg", body);
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    return data;
  } catch (error) {
    return error;
  }
}

export async function changePassword(body) {
  try {
    let data = await axios.post("/users/forgotpassword", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function editUserProfile(body) {
  try {
    let data = await axios.post(`/users/editprofile/${body.userId}`, body.body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function domainConfig(host) {
  try {
    let data = await axios.get(
      "https://onesaz-new.s3.ap-south-1.amazonaws.com/domain_config/" +
        host +
        ".json"
    );
    return data;
  } catch (error) {
    return error;
  }
}

// get time slots for all india exams
export async function allIndiaExamTimeSlots() {
  try {
    let data = await axios.get("/allIndiaTestSlots");
    return data;
  } catch (error) {
    return error;
  }
}
export async function logoutUser(payload) {
  const user = store.get("user");
  try {
    if (navigator.userAgent.includes("Android") && window.Android) {
      window.Android.clearFCMToken(`${payload.id}`);
    }
    let data = await axios.post("/users/logout", payload);
    if (user && user.level && user.level === "X0210780") {
      window.location.href = "https://miityedu.in";
    }
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function getUser(id) {
  try {
    const data = await axios.get(`/users/${id}`);
    return data.data;
  } catch (error) {
    throw error;
  }
}

export async function changeFirstLogin(payload) {
  try {
    let data = await axios.post("/users/changeFirstLogin", payload);
    return data.data;
  } catch (error) {
    return error;
  }
}
export async function getStudentForm(body) {
  try {
    return (await adminAxios.get("/parent-portal/doc",   {params: { ...body }}, ))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}