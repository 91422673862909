import { Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import AlertComponent from "./mycomponents/AlertComponent";
import { storeRedux } from "./redux/store/store";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import Chrome from "assets/img/chrome.png";
import Edge from "assets/img/edge.png";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./assets/theme";
// Routes
import Routes from "./routes/Routes";
import Layout from "./mycomponents/HomePage/Layout";
import { Route } from "react-router-dom";
const App = () => {
  const supportedBrowser = {
    Edge: true,
    Chrome: true,
    CriOS: true,
    Safari: true,
  };
  const [confirmOpen, setConfirmOpen] = useState(true);
  const handleCloseAlertBox = () => {
    setConfirmOpen(true);
  };

  /* getting browser name and version */
  const getBrowserDetails = () => {
    let userAgent = navigator.userAgent,
      tem,
      M =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = userAgent.match(/\bOPR\/(\d+)/);
      if (tem != null || window.navigator.userAgent.indexOf("Opera") > -1) {
        return { name: "Opera", version: tem[1] };
      }
    }
    if (
      window.navigator.userAgent.indexOf("Edg") > -1 ||
      window.navigator.userAgent.indexOf("Edge") > -1
    ) {
      tem = userAgent.match(/\Edg\/(\d+)/);
      if (tem != null) {
        return { name: "Edge", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: +M[1],
    };
  };
  const browser = getBrowserDetails();
  return (
    <React.Fragment>
      {browser.name in supportedBrowser ? (
        <Provider store={storeRedux}>
          <Router>
            <Routes />
          </Router>
        </Provider>
      ) : (
        <AlertComponent
          open={confirmOpen}
          closeAlert={handleCloseAlertBox}
          btnText=""
        >
          <WarningRoundedIcon fontSize="large" color="error" />
          <Typography color="error">Browser not supported!</Typography>
          <Typography variant="body2">
            Please switch to{" "}
            <a href="https://www.google.com/intl/en_in/chrome/">
              <img src={Chrome} alt="Chrome" />
            </a>{" "}
            or{" "}
            <a href="https://www.microsoft.com/en-us/edge">
              <img src={Edge} alt="Edge" />
            </a>{" "}
            and try again.
          </Typography>
        </AlertComponent>
      )}
    </React.Fragment>
  );
};
export default App;
