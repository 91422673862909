import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import "./AdvanceNumbersPanel.css";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import dhoniImage from "assets/dhoni.png";
import Avatar from "@material-ui/core/Avatar";
import store from "store";
import Hidden from "@material-ui/core/Hidden";
var classNames = require("classnames");
const submitDisabledDuration = {
  "sr.onesaz.com": 900,
  "sreducation.onesaz.com": 900,
  "bhashyam.onesaz.com": 300,
  "onesaz.com": 300,
  "localhost:4000": 300,
  "valleyoak.onesaz.com": 300,
  "sriabhidakp.onesaz.com": 300,
  "miity.onesaz.com": 300,
  "sriabhida.onesaz.com": 300,
  "sanskriti.onesaz.com": 300,
  "agastya.onesaz.com": 300,
  "tirumala.onesaz.com": 300,
  "pinegrove.onesaz.com": 300,
  "neutrinoadmin.onesaz.com": 300,
  "vijnaadmin.onesaz.com": 300,
  "sundar.onesaz.com": 300,
  "metamind.onesaz.com": 300,
  "ayati.onesaz.com": 300,
  "motion.onesaz.com": 4200,
  "excellencia.onesaz.com": 300,
  "arastu.onesaz.com": 300,
  "kle.onesaz.com": 300,
};
const styles = (theme) => ({
  // displayStatus:{
  //        borderRadius: '5px',
  //      width: '40px',
  //      fontSize: '14px',
  //      fontWeight: 'bolder',
  // },
  fab: {
    borderRadius: "0px",
    //  backgroundColor: 'unset',
    width: "40px",
    height: "40px",
    fontSize: "14px",
    fontWeight: "bolder",
    boxShadow: "none",
    //  width: 'max-content',
    backgroundSize: "90% 90% !important",
    // margin: theme.spacing(0.5),
    // width: theme.spacing(6),
    // height: theme.spacing(6),
    // backgroundColor: 'white',
    // color: '#474747',
    // border: 'solid 2px grey'
  },
  btnSubmit: {
    color: "#fff",
    // backgroundColor: '#5cb85c',
    background: "linear-gradient(45deg, #0B80F1 30%,#0B80F1 90%)",
    borderColor: "#4cae4c",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginRight: "3%",
    // marginBottom: theme.spacing(.5),
    // width: 'max-content',
    minWidth: theme.spacing.unit * 13,
    // minHeight: theme.spacing(9),
    padding: "-1% 15%",
    textAlign: "center",

    color: theme.palette.text.secondary,
  },
  mobilepaper: {
    // width: 'max-content',
    minWidth: theme.spacing.unit * 15,
    // padding: '8px 24px',
    textAlign: "center",
    margin: theme.spacing.unit * 1,
    color: theme.palette.text.secondary,
  },
  bottomleft: {
    // marginTop: theme.spacing(1),
    minHeight: theme.spacing.unit * 10,
    marginRight: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 1,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  bottomright: {
    minHeight: theme.spacing.unit * 10,
    // padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  newgridsep: {
    minHeight: theme.spacing.unit * 1,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
    paddding: "50px",
  },
  bigAvatar2: {
    margin: 10,
    width: 60,
    height: 60,
    paddding: "10px",
  },
  smallAvatar: {
    margin: 60,
    width: 60,
    height: 60,
    padding: "50px",
  },
});

class AdvanceNumbersPanel extends React.Component {
  a = [];
  statusmap = {
    NOT_VISITED: "adv-not-visd",
    NOT_ANSWERED: "adv-not-ansd",
    ANSWERED: "adv-ansd",
    MARKED_FOR_REVIEW: "adv-mark-reviewd",
    ANSWERED_MARKED_FOR_REVIEW: "adv-ans-marked-review",
  };
  statusdisplay = {
    NOT_VISITED: 0,
    NOT_ANSWERED: 0,
    ANSWERED: 0,
    MARKED_FOR_REVIEW: 0,
    ANSWERED_MARKED_FOR_REVIEW: 0,
  };
  componentDidUpdate(props) {
    if (this.props !== props) {
      this.statusCheck();
    }
  }
  statusCheck() {
    Object.keys(this.statusdisplay).forEach((key) => {
      this.statusdisplay[key] = 0;
    });
    this.props.questions.forEach((question) => {
      this.statusdisplay[question.status] += 1;
    });
    this.setState({ statusdisplay: this.statusdisplay });
  }
  constructor(props) {
    super(props);
    this.a = Array(this.props.questions.length)
      .fill(0)
      .map((v, i) => i + 1);
    this.state = { statusdisplay: this.statusdisplay };
  }
  sendQuestionIndex(index, status) {
    this.statusCheck();
    this.props.displayQuestion(index);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <Hidden smUp>
            <GridItem style={{ backgroundColor: "#FFFFFF" }}>
              <GridContainer
                style={{ padding: "0px", borderTop: "2px solid #494b5e" }}
              >
                <GridItem xs={4}>
                  {/* <Hidden only={['sm','xs']}> */}
                  <Avatar
                    alt="Profile"
                    src={dhoniImage}
                    className={classes.bigAvatar2}
                  />
                  {/* </Hidden> */}
                </GridItem>

                <GridItem xs={8}>
                  <div style={{ padding: "10px" }}>
                    <strong>
                      {store.get("user") && store.get("user").name}
                    </strong>
                    <br />
                    Roll No: {store.get("user") && store.get("user").userId}
                    <br />
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </Hidden>
          <Hidden smDown>
            <GridItem style={{ backgroundColor: "#FFFFFF", height: "125px" }}>
              <GridContainer style={{ padding: "18px" }}>
                <GridItem xs={4}>
                  {/* <Hidden only={['sm','xs']}> */}
                  <Avatar
                    alt="Profile"
                    src={dhoniImage}
                    className={classes.bigAvatar}
                  />
                  {/* </Hidden> */}
                </GridItem>

                <GridItem xs={8}>
                  <div style={{ padding: "25px" }}>
                    <strong>
                      {store.get("user") && store.get("user").name}
                    </strong>
                    <br />
                    Roll No: {store.get("user") && store.get("user").userId}
                    <br />
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </Hidden>
          {/* <span>
                                                    <img src={dhoniImage} style={{ border: '2px solid grey', verticalAlign: 'bottom' }} width="60px" height="60px" alt="" />
                                                </span> */}
        </GridContainer>
        <GridContainer style={{ background: "#E3EEFF" }}>
          <GridItem xs={5}>
            <Button
              size="sm"
              key="a"
              className={classNames(classes.fab, this.statusmap["ANSWERED"])}
            >
              {this.state.statusdisplay.ANSWERED}
            </Button>
            <span>Answered</span>
          </GridItem>

          <GridItem xs={6}>
            <Button
              size="sm"
              key="n_a"
              className={classNames(
                classes.fab,
                this.statusmap["NOT_ANSWERED"]
              )}
            >
              {this.state.statusdisplay.NOT_ANSWERED}
            </Button>
            <span> Not Answered</span>
          </GridItem>
          <GridItem xs={5}>
            <Button
              size="sm"
              key="a_m_r"
              className={classNames(classes.fab, this.statusmap["NOT_VISITED"])}
            >
              {this.state.statusdisplay.NOT_VISITED}
            </Button>
            <span> Not Visited </span>
          </GridItem>
          <GridItem xs={7}>
            <Button
              size="sm"
              key="m_r"
              className={classNames(
                classes.fab,
                this.statusmap["MARKED_FOR_REVIEW"]
              )}
            >
              {this.state.statusdisplay.MARKED_FOR_REVIEW}
            </Button>
            <span> Marked for Review</span>
          </GridItem>
          <GridItem>
            <Button
              size="sm"
              key="a_m_r"
              className={classNames(
                classes.fab,
                this.statusmap["ANSWERED_MARKED_FOR_REVIEW"]
              )}
            >
              {this.state.statusdisplay.ANSWERED_MARKED_FOR_REVIEW}
            </Button>
            <span>Answered & Marked for Review </span>
            <span style={{ fontSize: "14px" }}>
              (will consider for evaluation)
            </span>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" style={{ background: "#178DFF" }}>
          <GridItem xs={12}>
            <h4 style={{ color: "white", fontWeight: "500" }}>
              {" "}
              {this.props.current_question.subject &&
                this.props.current_question.subject.substr(0, 3)}{" "}
              SEC {this.props.current_question.tempQNo}
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer
          justify="center"
          className="indexPanelAdv"
          style={{ background: "#E3EEFF" }}
        >
          {/* <GridItem xs={12}>
                        <Grid container > */}
          <GridItem>
            {this.props.questions
              .filter((question, index) => {
                return (
                  this.props.schema.questions[index + 1] ===
                    this.props.schema.questions[
                      this.props.current_question.tempQNo
                    ] &&
                  question.subject === this.props.current_question.subject
                );
              })
              .map((question) => {
                return (
                  <Button
                    size="sm"
                    key={question.question_number}
                    onClick={() =>
                      this.sendQuestionIndex(question.tempQNo, question.status)
                    }
                    aria-label="Add"
                    className={classNames(
                      classes.fab,
                      this.statusmap[question.status]
                    )}
                  >
                    {question.tempQNo}
                  </Button>
                );
              })}
          </GridItem>
          <GridItem xs={12} sm>
            <Button
              size="lg"
              disabled={
                this.props.remainingTime / 1000 >
                submitDisabledDuration[window.location.host]
              }
              onClick={() => this.props.submit()}
              className={classes.btnSubmit}
            >
              Submit
            </Button>
          </GridItem>
          {/* </Grid>
                    </GridItem> */}
        </GridContainer>
      </div>
    );
  }
}

AdvanceNumbersPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles)
)(AdvanceNumbersPanel);
// export default withStyles(styles)(NumbersPanel)
