import axios from "./examServerAxios";

export async function getAllNotifications(body) {
  try {
    let data = await axios.post("/analysis/getnotifications", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getRocketChat(body) {
  try {
    let data = await axios.post("/chat/chatLogin", body);
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function getRocketChatSSO(body) {
  try {
    let data = await axios.post("/chat/chatSSO", body);
    return data.data;
  } catch (error) {
    return error;
  }
}
