var ENV = process.env.NODE_ENV || "development";

var host = "http://localhost:";

function getAPIUrlConstants() {
  var obj = {
    production: {
      // BASE_URL: "http://18.233.225.151/api",
      BASE_URL: "http://35.154.83.13/api",
      EXAM_SERVER_URL: window.location.origin + "/api",
      ANALYTICS_URL: window.location.origin + "/api/analysis/updatetime",
      ZOOM_APIKey: "wvbNv8fsTiqEmvrL0Bz4kQ",
      ZOOM_APISecret: "xyme1EfByoS7kJxSWVEsgiN7hfpae5QlNGiF",
      ZOOM_SDK_KEY: "6jU9Gi1UQWyWxVgfLardMw",
      ADMIN_URL: "https://admin.onesaz.com/api"

    },
    development: {
      // http://13.235.2.132/api/exams/mocktest
      BASE_URL: host + "5890",
      EXAM_SERVER_URL: host + "8912",
      // ANALYTICS_URL: "https://eyj83dw0d7.execute-api.us-east-1.amazonaws.com/test/studentbehaviour"
      // BASE_URL: "http://35.154.83.13/api",
      // EXAM_SERVER_URL: "https://onesaz.com/api",
      //  EXAM_SERVER_URL: "http://15.207.16.248/api",
      // EXAM_SERVER_URL: "https://onesaz.com/api",
      // EXAM_SERVER_URL: host+"4000",
      ANALYTICS_URL: "http://13.235.2.132/api/analysis/updatetime",
      ZOOM_APIKey: "wvbNv8fsTiqEmvrL0Bz4kQ",
      ZOOM_APISecret: "xyme1EfByoS7kJxSWVEsgiN7hfpae5QlNGiF",
      ZOOM_SDK_KEY: "6jU9Gi1UQWyWxVgfLardMw",
      ADMIN_URL: host + "7077"
    },
  };
  return obj[ENV];
}

export default getAPIUrlConstants();
