import { Box, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import PauseIcon from "@material-ui/icons/Pause";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import Button from "components/CustomButtons/Button.jsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import store from "store";
import { ReactComponent as BarChart } from "../../assets/img/bar-chart.svg";
import extractString from "../../common/extractString";
import ExamCard from "./ExamCard";
import theme from "../../assets/theme";

const useStyles = makeStyles(() => ({
  divStyle: {
    padding: "0 2%",
  },
  spanColor: {
    color: "#08C5A7",
  },
  statusFont: {
    fontWeight: 600,
  },
}));
const HomeCards = (props) => {
  const [shouldStart, setShouldStart] = useState(
    moment(props.exam.startDateTime).isBefore(moment(props.exam.time_check))
  );
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const [time, setTime] = useState(Date.now());
  const [errorIfCodeNotMatched, setErrorIfCodeNotMatched] = useState(false);
  const handleCloseAlert = () => {
    setConfirmOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleTextField = (event) => {
    setCodeValue(event.target.value);
  };
  const handleVerifyCodeAndProceed = () => {
    if (codeValue === props.exam.verificationCode) {
      props.enterexam();
      setOpen(false);
    } else {
      setErrorIfCodeNotMatched(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    if (moment(props.exam.time_check).isAfter(props.exam.startDateTime)) {
      setShouldStart(true);
    }
    return () => {
      clearInterval(interval);
    };
  });
  const { exam } = props;
  const classes = useStyles();
  const { origin } = window.location;
  return (
    <div className={classes.divStyle}>
      <ExamCard
        title={extractString(props.exam.testDisplayName)}
        testDisplayName={extractString(props.exam.testDisplayName)}
        onClick={() => {
          if (
            (props.proceedas == "START" && !shouldStart) ||
            props.proceedas == "EXPIRED"
          ) {
            if (
              origin === "https://onesaz.com" ||
              origin === "https://www.onesaz.com"
            ) {
              if (store.get("user").statusType === "paid") {
                setConfirmOpen(true);
              } else {
                props.payment();
              }
            }
          } else if (
            props.proceedas == "START" &&
            origin.includes("bhashyam") &&
            props.exam.verificationCode
          ) {
            handleClickOpen();
          } else props.enterexam();
        }}
        button={
          props.proceedas == "START" && !shouldStart ? (
            <Button
              onClick={() => {
                if (props.proceedas == "START" && !shouldStart) {
                  setConfirmOpen(true);
                } else props.enterexam();
              }}
            >
              <Typography
                className={classes.statusFont}
                style={{ color: theme.palette.secondary.main }}
              >
                Upcoming
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (props.proceedas == "EXPIRED") {
                } else props.enterexam();
              }}
            >
              {(() => {
                if (props.proceedas == "EXPIRED") {
                  return (
                    <Typography
                      className={classes.statusFont}
                      style={{
                        color: theme.palette.warning.main,
                      }}
                    >
                      Absent
                    </Typography>
                  );
                }
                if (props.proceedas == "VIEW") {
                  return (
                    <Typography
                      className={classes.statusFont}
                      style={{
                        color: theme.palette.primary.main,
                      }}
                    >
                      Previous
                    </Typography>
                  );
                }
                if (props.proceedas == "START") {
                  return exam.freeExam !== undefined &&
                    !exam.freeExam &&
                    store.get("user").statusType !== "paid" ? (
                    <LockIcon />
                  ) : null;
                } else {
                  return (
                    <Typography
                      className={classes.statusFont}
                      style={{
                        color: theme.palette.info.main,
                      }}
                    >
                      Continue
                    </Typography>
                  );
                }
              })()}
            </Button>
          )
        }
        timeStarted={
          props.exam.time_started == "NA" ? (
            ""
          ) : (
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" variant="caption">
                Exam started on:
              </Typography>
              <Typography color="textPrimary" variant="caption">
                <b>
                  {moment(props.exam.time_started).format(
                    "MMM DD, YYYY hh:mm:ss"
                  )}
                </b>
              </Typography>
            </Box>
          )
        }
        examType={
          props.exam.type && (
            <a
              style={{ color: "#08c5a7" }}
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              {props.exam.test_type == undefined ? " " : props.exam.type.trim()}
            </a>
          )
        }
        examPaper={
          exam.paper == "undefined" ||
          exam.paper === undefined ||
          exam.paper === null
            ? "   "
            : props.exam.paper.split("_").join(" ")
        }
        endDateTime={
          props.exam.endDateTime && props.proceedas !== "START" ? (
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" variant="caption">
                Exam ends on:
              </Typography>
              <Typography color="textPrimary" variant="caption">
                <b>
                  {moment(props.exam.endDateTime).format(
                    "MMM DD, YYYY hh:mm:ss"
                  )}
                </b>
              </Typography>
            </Box>
          ) : (
            <div>
              <Typography color="textPrimary" variant="caption">
                {moment().isAfter(props.exam.startDateTime) ? (
                  <span className={classes.spanColor}>
                    Reload to start the test
                  </span>
                ) : (
                  "Exam will be active after " +
                  moment(props.exam.startDateTime).format(
                    "MMM DD, YYYY hh:mm:ss"
                  )
                )}
              </Typography>
            </div>
          )
        }
      />
      {/* verification code dialog box */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Enter verification code
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To proceed further, please enter verification Code here.
          </DialogContentText>
          <TextField
            error={errorIfCodeNotMatched ? true : false}
            autoFocus
            margin="dense"
            id="name"
            label="Verification code"
            type="text"
            value={codeValue}
            fullWidth
            onChange={handleTextField}
            helperText={
              errorIfCodeNotMatched ? "Invalid verification code!" : null
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleVerifyCodeAndProceed} color="primary">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      {/* ----------------------------------- */}
    </div>
  );
};

export default HomeCards;
