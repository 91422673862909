import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import "assets/scss/material-kit-pro-react.scss?v=1.3.0";

// import PrivateRoute from "./routes/PrivateRoute";
// import SignIn from "./views/SignIn/SignIn";
// import NotFound from "./views/NotFound/NotFound";
// import ExamPortal from "./mycomponents/ExamPortal";
// import Home from "./mycomponents/Home";
// import Instruction from "./mycomponents/Instruction";
// import ExamSummary from "./mycomponents/ExamSummary";
// import Results from "./mycomponents/Results";
// import DetailView from "./mycomponents/DetailView";
// import verify from "./hoc/authGaurd";
// import Header from "./mycomponents/Header";
// import { BrowserRouter, Link } from "react-router-dom";
import App from "./App";
import { Router } from "react-router-dom";
import "./index.css";

export const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById("root")
);
